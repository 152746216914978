import React, { useEffect, useState } from 'react';
import { Typography } from './Typography';

export type InputVariant = 'default' | 'compact';

export interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
  className?: string;
  allowedChars?: string[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: InputVariant;
  endAdornment?: React.ReactNode; // Add endAdornment prop
  startAdornment?: React.ReactNode; // Add startAdornment prop
  error?: string;
}

export const Input = ({
  label,
  className,
  type,
  onChange,
  disabled,
  value: propsValue,
  defaultValue,
  variant = 'default',
  allowedChars,
  onFocus,
  onBlur,
  endAdornment, // Destructure endAdornment prop
  startAdornment,
  error,
  ...props
}: IInput) => {
  const [value, setValue] = useState(defaultValue ?? '');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (propsValue !== undefined) {
      setValue(propsValue.toString());
    }
  }, [propsValue]);

  const mustMoveLabel = isFocused || (value && value.length > 0);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (allowedChars) {
      const regex = new RegExp(`^[${allowedChars.join('')}]*$`);
      if (!regex.test(e.target.value)) return;
    }
    setValue(e.target.value);
    onChange && onChange(e);
  };

  const inputClassName = `${disabled && '!bg-[#e9e9e9]'} w-full rounded-[8px] border-[1px] border-[#CDCDCD] bg-white p-[10px_12px] font-normal placeholder-[#12121299] ${className}`;

  if (variant === 'default') {
    return (
      <div className="relative w-full">
        {label && <label className="mb-2">{label}</label>}
        {startAdornment && (
          <div className="absolute left-0 flex h-full items-center">
            {startAdornment}
          </div>
        )}{' '}
        <div className="flex h-full w-full items-center">
          <input
            onChange={handleChange}
            disabled={disabled}
            type={type ?? 'text'}
            data-testid="input-default"
            onFocus={onFocus}
            onBlur={onBlur}
            value={value}
            className={inputClassName}
            {...props}
          />
          {error && (
            <Typography variant="caption" className="text-error">
              {error}
            </Typography>
          )}
          {endAdornment && (
            <div className="absolute right-0">{endAdornment}</div>
          )}{' '}
          {/* Render endAdornment if provided */}
        </div>
      </div>
    );
  } else if (variant === 'compact') {
    return (
      <div className="relative w-full">
        <input
          onFocus={e => {
            setIsFocused(true);
            onFocus && onFocus(e);
          }}
          disabled={disabled}
          data-testid="input-compact"
          onBlur={e => {
            setIsFocused(false);
            onBlur && onBlur(e);
          }}
          onChange={handleChange}
          type={type ?? 'text'}
          value={value}
          className={`${inputClassName} h-[40px] pt-[20px]`}
          {...props}
        />
        {error && (
          <Typography variant="caption" className="absolute left-1 text-error">
            {error}
          </Typography>
        )}
        <label
          style={{
            top: mustMoveLabel ? '5px' : '12px',
            fontSize: mustMoveLabel ? '10px' : '16px'
          }}
          className="pointer-events-none absolute left-[13px] text-left text-placeholder transition-all duration-300 ease-in-out"
        >
          {label}
        </label>
        {endAdornment && (
          <div className="absolute right-[12px] top-[50%] translate-y-[-50%]">
            {endAdornment}
          </div>
        )}{' '}
        {/* For compact variant */}
      </div>
    );
  }

  return null;
};
