import { Address } from './address';

export class Candidate {
  constructor(
    public firstName?: string,
    public lastName?: string,
    public description?: string,
    public phone?: string | null,
    public intakeProgress?: number,
    public birthDate?: Date | null,
    public profilePicture?: string | null,
    public address: Address | null = null
  ) {}

  static fromJson(json: any): Candidate {
    return new Candidate(
      json.firstName,
      json.lastName,
      json.description,
      json.phone,
      json.intakeProgress,
      json.birthDate ? new Date(json.birthDate) : null,
      json.profilePicture,
      json.address ? Address.fromJson(json.address) : null
    );
  }
}
