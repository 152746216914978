import { useToast } from '@elements/Toast';
import { KoraApi } from '@services/api';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useDemoSignup = () => {
  const [email, setEmail] = useState<string>();
  const { success, error } = useToast();
  const { t } = useTranslation('landing');
  const [isLoading, setIsLoading] = useState(false);

  const submit = async () => {
    try {
      setIsLoading(true);
      await KoraApi.post('/auth/demo', { email });
      success(t('signed_up'));
    } catch (e) {
      error(t('error_signing_up'));
    } finally {
      setIsLoading(false);
    }
  };

  const memoized = useMemo(() => {
    const isValidEmail = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    return {
      isValid: isValidEmail,
      submit,
      setEmail,
      isLoading
    };
  }, [email, isLoading]);

  return memoized;
};
