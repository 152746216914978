/** @type {import('tailwindcss').Config} */
export default {
  content: ['./src/**/*.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      keyframes: {
        'fade-in': {
          '0%': { opacity: '0', transform: 'translateY(50px)' },
          '100%': { opacity: '1', transform: 'translateY(0)' }
        }
      },
      animation: {
        'fade-in': 'fade-in-up 2s ease 0s 1 forwards'
      },
      colors: {
        primary: '#171BAF',
        secondary: '#3B55F6',
        success: '#5cb85c',
        error: '#ED4337',
        placeholder: '#444',
        gray: '#c5c5c5',
        dark: '#016'
      },
      fontSize: {
        '4xl': '2.5rem'
      }
    }
  },
  plugins: []
};
